import React, { useState  } from 'react';
import './stateGate.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import statesList from './stateList';
import { Select, MenuItem } from "@material-ui/core";

function StateGate({page, stateGate, updateStateGate, title, instructions, selectedState, setSelectedState, invalidStates }) {

    const block = page.components.find(component => component.block_type === 'StateGateBlock');

    const handleChange = (event) => {
        setSelectedState(event.target.value);
    };

    const ColoredArrowDownIcon = ({ color }) => (
        <KeyboardArrowDownIcon style={{ color }} />
    );

    return((
        <div className={`state-gate-container`}>
            <div className={`state-gate`}>
                <h2>{title}</h2>
                <div className="select-container">
                    <div className='state-inputs'>
                        <Select
                            displayEmpty
                            renderValue={(value) => (value ? statesList.find((option) => option.value === value).title : statesList[0].title)}
                            IconComponent={() => <ColoredArrowDownIcon color={invalidStates ? block.text_color : '#000'} />}
                            value={selectedState}
                            onChange={handleChange}
                            className="select-dropdown"
                            style={{
                                color: invalidStates ? block.text_color : '#000',
                                borderBottom: `2px solid ${block.border_color}`,
                            }}
                            inputProps={{
                                'aria-label': 'State',
                            }}
                        >
                            {statesList.map((option, index) => (
                                <MenuItem key={option.title} value={option.value} data-index={index}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <label>{instructions}</label>
            </div>
        </div>
    ));
}

export default StateGate