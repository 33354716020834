import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import HeaderNavigation from "../blocks/headerNavigation";
import FooterV2 from "../footerV2/footer";
import { CLEAR_USER } from "../redux/reducers/user";
import getComponent from "../shared/util/index";

function AccountPage({ page }) {
  const pageConfig = page.find((page) => page.slug === "account");
  const retailerConfig = useSelector((state) => state.config.retailerConfig);

  const header = retailerConfig.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const footer = retailerConfig.components.find(
    (component) => component.block_type === "FooterBlock"
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSignOut = () => {
    dispatch(CLEAR_USER());
    localStorage.clear();
    navigate(`/${page.brandsite_name}`);
  };

  return (
    <>
      {header && <HeaderNavigation page={retailerConfig} />}

      {pageConfig && pageConfig.components.map((blockConfig) => (
        <>{getComponent(blockConfig.block_type, pageConfig, blockConfig)}</>
      ))}

      <div style={{
          display: "flex",
          justifyContent: "center",
          width: `100%`,
          margin: '1rem',
        }}>
             <div
        style={{
          backgroundColor: "red",
          borderRadius: `10px`,
          display: "flex",
          color: "#000",
          justifyContent: "center",
          padding: `9px 15px`,
          width: "250px",
          fontSize: "16px",
          cursor: 'pointer'
        }}
        onClick={onSignOut}
        variant="contained"
      >
        Sign Out
      </div>
        </div>
     

      {footer && <FooterV2 page={retailerConfig} />}
    </>
  );
}

export default AccountPage;
