import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CameraModal from "./CameraModal";
import CapturedImages from "./CapturedImages";

function WebcamCapture({ props }) {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "80px 24px",
    },
    headerTextContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "64px",
      alignItems: "center",
      [theme.breakpoints.between("xs", "sm")]: {
        marginBottom: "32px",
      },
    },
    headerText: {
      fontSize: "91px",
      fontWeight: "800",
      lineHeight: "90%",
      maxWidth: "760px",
      color: "#0054A6",
      textAlign: "center",
      letterSpacing: "-0.91px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "28px",
        fontWeight: "900",
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      height: "400px",
      width: "1000px",
      border: "3px dashed #0054A6",
      justifyContent: "center",
      backgroundColor: "#E6EEF6",
      borderRadius: "24px",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "350px",
        minHeight: "340px",
      },
    },
    button: {
      borderRadius: "240px",
      border: "2px solid #0073C5",
      backgroundColor: "#FFF",
      width: "187px",
      boxShadow:
        "0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)",
      alignSelf: "center",
    },
    infoBox: {
      display: "flex",
      justifyContent: "center",
      margin: "32px 0px",
      [theme.breakpoints.between("xs", "sm")]: {
        margin: "24px 0px",
      },
    },
    subInfoBox: {
      padding: "0px 16px",
    },
    boldText: {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "150%",
      color: "#25272C",
      margin: "0",
      textAlign: "center",
    },
    infotext: {
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "150%",
      color: "#6B7280",
      margin: "0",
      textAlign: "center",
    },
  }));
  const classes = useStyles();

  const [capturedImages, setCapturedImages] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (capturedImages.length < 10) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setImages = (imgSrc) => {
    // adding captured images to the state
    const data = imgSrc;
    capturedImages.push(data);
    setCapturedImages([...capturedImages]);
  };

  const uploadFiles = () => {
    // to be updated once api is build
    setCapturedImages([]);
  };
  const deleteImage = (index) => {
    // to delete captured images
    const updatedImages = capturedImages;
    updatedImages.splice(index, 1);
    setCapturedImages([...updatedImages]);
  };

  return (
    <div className={classes.container}>
      <CameraModal
        handleClose={handleClose}
        open={open}
        setImages={setImages}
      ></CameraModal>
      <div className={classes.headerTextContainer}>
        <h1 className={classes.headerText}>Upload Receipts to Earn Points</h1>
      </div>
      <div className={classes.uploadBox}>
        <Button className={classes.button} onClick={handleOpen}>
          Take a Photo
        </Button>
        <div className={classes.infoBox}>
          <div className={classes.subInfoBox}>
            <p className={classes.boldText}>25 MB</p>
            <p className={classes.infotext}>max size</p>
          </div>
          <div
            className={classes.subInfoBox}
            style={{ borderLeft: "1px solid #B6BAC3" }}
          >
            <p className={classes.boldText}>.jpg .png</p>
            <p className={classes.infotext}>file types</p>
          </div>
        </div>
        <div>
          {/* displaying captured images */}
          <CapturedImages
            capturedImages={capturedImages}
            deleteImage={deleteImage}
          ></CapturedImages>
        </div>
        {capturedImages && capturedImages.length > 0 && (
          <Button
            className={classes.button}
            style={{
              marginTop: "24px",
              backgroundColor: props?.color_background || "#0073C5",
              color: "#fff",
            }}
            onClick={uploadFiles}
          >
            Upload Files
          </Button>
        )}
      </div>
    </div>
  );
}

export default WebcamCapture;
