import HeaderNavigation from '../blocks/headerNavigation';
import FooterV2 from '../footerV2/footer';
import getComponent from '../shared/util/index';

function HomePage({ page }) {
  const retailerConfig = page[0];
  const homePageConfig = page.find((page) => page.slug === 'home');

  const header = retailerConfig.components.find((component) => component.block_type === 'HeaderNavigationBlock');
  const footer = retailerConfig.components.find((component) => component.block_type === 'FooterBlock');

  return (
    <>
      {header && <HeaderNavigation page={retailerConfig}/>}

      {homePageConfig && homePageConfig.components.map((blockConfig) => (
        <>{getComponent(blockConfig.block_type, homePageConfig, blockConfig, retailerConfig)}</>
      ))}

      {footer && <FooterV2 page={retailerConfig} />}
    </>
  );
}

export default HomePage;
