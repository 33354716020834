import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from 'react-multi-carousel';
import { OfferCard } from './carouselCard';
import { OfferCardV2 } from './carouselCardV2';
import 'react-multi-carousel/lib/styles.css';
import './offerCarouselStyles.scss';

function OfferCarousel({ component, page }) {
  const offerCarouselBlock = component;
  const carouselItems = offerCarouselBlock.carousel_items;
  const version = offerCarouselBlock.version;

  const defaultColorTheme = {
    primary_color: "#000",
    primary_button_text_color: "#fff",
    secondary_color: "#ccc",
    link_color: "#000",
    text_color: "#000",
    background_color: "#ffffff"
  };

  const colorTheme = page.components.find((component) => component.block_type === 'ColorTheme') || defaultColorTheme;

  const offerCards = carouselItems?.map((item) => {
    if (version === 'v1') {
      return (
        <OfferCard
          url={item.image}
          mdid={item.offer_id}
          block={offerCarouselBlock}
          image_alt_text={item.image_alt_text}
          colors={colorTheme}
        />
      );
    } else {
      return (
        <OfferCardV2
          url={item.image}
          mdid={item.offer_id}
          block={offerCarouselBlock}
          image_alt_text={item.image_alt_text}
          colors={colorTheme}
          page={page}
        />
      );
    }
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div className='carousel-container'>
      <br />
      <Carousel 
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
      >
        {offerCards}
      </Carousel>
      <br />
    </div>
  );
}

export default OfferCarousel;
