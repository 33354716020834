import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';

import './hero.scss';

function Hero(page) {
  const siteConfig = page.page;
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function StaticHero() {
    const hero = siteConfig.components.find(component => component.block_type === 'HeaderBlock').header_set[0];
    return (
      <img
        className="heroImg"
        src={isMobile ? hero.header_image_mobile : hero.header_image}
        alt={hero.header_image_alt_text}
      />
    );
  }

  function HeroCarousel() {
    const heroCarousel = siteConfig.components.find(component => component.block_type === 'HeaderBlock').header_set;
    return (
      <Carousel
        autoPlay={true}
        interval={5000}
        infiniteLoop={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        swipeable={true}
        onClickItem={anchorItem}
      >
        {heroCarousel.map((item, i) => (
          <a href={item.anchor} key={i}>
            <img
              className="heroImg"
              src={item.header_image}
              alt={item.header_image_alt_text}
            />
          </a>
        ))}
      </Carousel>
    );
  }

  function anchorItem(i, child) {
    if (child.props.href) {
      window.location.href = child.props.href;
    }
  }

  const hero = siteConfig.components.find(component => component.block_type === 'HeaderBlock');
  if (!shouldRenderComponent(hero.auth_visibility)) {
    return null;
  }
  
  return (
    <div className='header'>
      <div className="hero">
        {hero.header_set.length > 1 && <HeroCarousel />}
        {hero.header_set.length === 1 && <StaticHero />}
      </div>
    </div>
  );
}

export default Hero;
