import ProgramExplanation from "../../programExplanation/programExplanation";
import EnrolledPrograms from "../../MyLoyalty/EnrolledPrograms";
import RecommendedProgrms from "../../MyLoyalty/RecommendedProgrms";
import OffersBlock from "../../offerBlock/index";
import ChatbotButton from "../../Button/index";
import ContactBlock from '../../contact/index';
import GardenImages from "../../logoGarden/index";
import InstagramSection from "../../instagram/index";
import TikTokSection from "../../tiktok/index";
import Rebates from "../../rebates/index";
import Chatbot from "../../chatbot/index";
import FAQBlock from "../../faq/index";
import FreeText from "../../freeText/index";
import LP_Header from "../../pages/Home/LandingPage/LP_header";
import Hero from "../../hero";
import LoyaltyBlock from "../../loyalty/index";
import InformationBanner from "../../informationBanner/index";
import Cart from "../../cart/index";
import Recipes from "../../recipes/index";
import SweepstakesEmbed from '../../sweepstakesEmbed/sweepstakesEmbed';
import OfferCarousel from "../../offerCarousel/offerCarousel";
import MetaDataBlock from "../../metadata/index";
import WebcamCapture from "../../UploadReceipt/WebcamCapture";

const getComponent = (blockType, pageConfig, blockConfig, retailerConfig) => {

  const blockToComponentMap = {
    AddToCartBlock: <Cart component={blockConfig} />,
    HeaderBlock: <Hero page={pageConfig} />,
    ProgramExplanationBlock: <ProgramExplanation page={pageConfig} />,
    RecommendedProgramsPreviewBlock: <RecommendedProgrms props={pageConfig} />,
    EnrolledPrograms: <EnrolledPrograms props={pageConfig} />,
    OfferBlock: <OffersBlock page={pageConfig} />,
    LogoGardenBlock: <GardenImages component={blockConfig} />,
    InstagramBlock: <InstagramSection component={blockConfig} />,
    TikTokBlock: <TikTokSection component={blockConfig} />,
    ChatbotButtonBlock: <ChatbotButton component={blockConfig} />,
    ContactBlock: <ContactBlock page={pageConfig} />,
    Rebates: <Rebates page={pageConfig} />,
    ChatbotBlock: <Chatbot page={pageConfig} />,
    FAQBlock: <FAQBlock component={blockConfig} />,
    LP_HeaderBlock: <LP_Header props={pageConfig} site_Config={retailerConfig} />,
    LoyaltyBlock: <LoyaltyBlock page={pageConfig} />,
    QualifyingProductsBlock: <InformationBanner page={pageConfig} />,
    RecipeBlock: <Recipes page={pageConfig} />,
    OfferCarouselBlock: <OfferCarousel component={blockConfig} page={pageConfig} />,
    MetaDataBlock: <MetaDataBlock page={pageConfig} />,
    UploadReceiptBlock: <WebcamCapture page={pageConfig} />, 
    FreeTextBlock: <FreeText component={blockConfig} />,
    SweepstakesBlock : <SweepstakesEmbed component={blockConfig} />,

    // 'AgeGateBlock': <CheckboxAgeGate page={page}/>,
  };

  return blockToComponentMap[blockType];
};

export default getComponent;
