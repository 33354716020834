import React from "react"; 
import {Grid} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

function CapturedImages({capturedImages, deleteImage}) {
  return (
    <Grid  
        container
        direction="row"
        alignItems="stretch"
        justifyContent="center"
        >
    { 
    capturedImages && capturedImages.length>0 &&
        capturedImages.map((item, i)=>{
        return(
        <Grid item key={i} id={i+'_img'} style={{margin:'4px', position:'relative'}}>
            {/* cross icon to delete captured image */}
            <ClearIcon style={{color:'red', position:'absolute', top:0, right:0, zIndex:999}} onClick={()=>deleteImage(i)}></ClearIcon>
            <img width={50} height={70}src={item}
            />
            
        </Grid>

            )
            })
        }
    </Grid>
  )
}

export default CapturedImages