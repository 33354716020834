import React, {useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Webcam from 'react-webcam';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  paper: {
    display:'flex',
    justifyContent:'space-evenly',
    flexDirection:'column',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 4, 3),
    height:'100vh',
    width:'100%'
  },
  content:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center'
  },
  camView:{
    display:'flex',
    justifyContent:'center',
    width:'100%',
    maxWidth:'530px',
    height:'80vh',
    border: '3px dashed #0054A6',
    alignSelf:'center',
  },
  button:{
    borderRadius:'240px',
    border: '2px solid #0073C5',
    backgroundColor:'#0073C5',
    color:'#fff',
    width:'187px',
    boxShadow: '0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)',
    alignSelf:'center',
    '& :hover':{
      color: 'black',
  },
  }
}));

export default function CameraModal({setImages, handleClose, open}) {

  const [isShowVideo, setIsShowVideo] = useState(true);
    const videoElement = useRef(null);
    
    const videoConstraints = {
        width: 300,
        height: 650,
        facingMode: "user"
         // below to used for back camera if req
        // facingMode: { exact: "environment" }
    }
    const capture = React.useCallback(() => {
      const imageSrc = videoElement.current.getScreenshot();
      return imageSrc
    }, [videoElement]);

    const handleCapture = ()=>{
      const imageSrc = capture()
      setImages(imageSrc);
      handleClose()
    }


  const classes = useStyles();

  const body = (
    <div  className={classes.paper}>
      <div className={classes.content}>
          <span>
            Place the photo inside the dashed box!
          </span>
          <Button className={classes.button} onClick={handleCapture}>Capture photo</Button>
      </div>

      <div className={classes.camView}>
          {isShowVideo &&
              <Webcam audio={false} ref={videoElement} videoConstraints={videoConstraints} screenshotFormat="image/png"/>
          }
      </div>

      <CameraModal />
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
        
      </Modal>
    </div>
  );
}