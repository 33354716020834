import React, { useState } from 'react';
import './ageGate.scss';
import StateGate from '../../stateGate';
import statesList from '../../stateGate/stateList';

function DateAgeGate({ page, ageGate, updateAgeGate, title, instructions, instructions2, minimum_age }) {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [isUnderAge, setIsUnderAge] = useState(false);
  const [yearError, setYearError] = useState('');
  const [dateError, setDateError] = useState('');
  const [numericError, setNumericError] = useState('');
  const block = page.components.find(component => component.block_type === 'AgeGateBlock');

  //state gate block and prop values
  const [selectedState, setSelectedState] = useState(statesList[0].value);
  const [invalidStates, setInvalidStates] = useState(false);
  const stateGate = page.components.find((component) => component.block_type === 'StateGateBlock');
  const [stateGateLocked, setStateGateLocked] = useState(stateGate ? true : false);

  const birthdayDate = month + '/' + day + '/' + year;
  const terms_link = block.terms_link;
  const privacy_link = block.privacy_link;

  function trackStateAndAge() {
    window.gtag('event', 'stateAndAgeClick', {
      birthday: birthdayDate,
      state: selectedState,
      version: localStorage.getItem("AB_TEST_VARIABLE"),
    });
  }

  const handleFormAgeSubmit = (event) => {
    event.preventDefault();
    const ageValidationResult = validateAge();
    const stateValidationResult = stateGate ? isValidState() : true;
    const isNumeric = (value) => /^\d+$/.test(value);

    if (!isNumeric(month) || !isNumeric(day) || !isNumeric(year)) {
      setNumericError('Please enter only numeric values for the date.');
      setDateError('')
      setIsUnderAge(false);
      setInvalidStates(false);
      return;
    } else {
      setNumericError('');
    }
  
    if (ageValidationResult && stateValidationResult) {
      updateAgeGate(false);
      if (stateGate) {
        updateStateGate(false);
      }
    } else {
      if (!ageValidationResult) {
        setIsUnderAge(true);
      }
      if (stateGate && !stateValidationResult) {
        setInvalidStates(true);
      }
    }
  
    if (stateGate && isValidState()) {
      setInvalidStates(false);
    }
    trackStateAndAge();
  };
      
  const isValidDate = () => {
    // Validate month, day, and year
    if (!month || !day || !year) {
      return false;
    }

    const numericMonth = parseInt(month, 10);
    const numericDay = parseInt(day, 10);
    const numericYear = parseInt(year, 10);

    if (isNaN(numericMonth) || isNaN(numericDay) || isNaN(numericYear)) {
      return false;
    }

    if (numericMonth < 1 || numericMonth > 12) {
      setDateError('Please input valid date')
      setIsUnderAge(false);
      setInvalidStates(false);
      return false;
    } else {
      setDateError('')
    }

    const daysInMonth = new Date(numericYear, numericMonth, 0).getDate();
    if (numericDay < 1 || numericDay > daysInMonth) {
      setDateError('Please input valid date');
      setIsUnderAge(false);
      setInvalidStates(false);
      return false;
    } else {
      setDateError('');
    }

    return true;
  };

  const isValidYear = () => {
    if (year.length !== 4) {
      setYearError('Please enter a valid year');
      return false;
    }
    setYearError('');
    return true;
  };

  const validateAge = () => {
    if (isValidDate() && isValidYear()) {
      const currentDate = new Date();
      const inputDate = new Date(`${year}-${month}-${day}`);
      const ageDiff = currentDate.getFullYear() - inputDate.getFullYear();
      const monthDiff = currentDate.getMonth() - inputDate.getMonth();
      const dayDiff = currentDate.getDate() - inputDate.getDate();
  
      if (
        ageDiff > block.minimum_age ||
        (ageDiff === block.minimum_age && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
      ) {
        setIsUnderAge(false);
        return true;
      } else {
        setIsUnderAge(true);
        return false;
      }
    }
    setIsUnderAge(true);
    return false;
  };

  //handle state gate validation
  const isValidState = () => {
    return stateGate.allowed_states.some((state) => state.code === selectedState);
  };
  
  const updateStateGate = (value) => {
    setStateGateLocked(!stateGate);
  };

  const getErrorMessage = () => {
    if (isUnderAge && invalidStates) {
      return (
        <p className="error-message">
          Sorry, you must be {block.minimum_age} years old to enter this site. Also, there are no rebates available in your state.
        </p>
      );
    } else if (isUnderAge) {
      return (
        <p className="error-message">
          You must be {block.minimum_age} years or older to access this website.
        </p>
      );
    } else if (invalidStates) {
      return (
        <p className="error-message">There are no rebates in your state.</p>
      );
    }
    return null;
  };

  return (
    (
      <div className={`age-gate-container`}>
        <div className={`age-gate`}>
          <form onSubmit={handleFormAgeSubmit}>
            <div className="form-group">
              {stateGate && (<StateGate 
                stateGateLocked={stateGateLocked}
                updateStateGate={updateStateGate}
                title={stateGate.title}
                instructions={stateGate.instructions}
                page={page}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                invalidStates={invalidStates}
              />)}
              <h2>{title}</h2>
              <label>{instructions}</label>
              <div className="date-inputs">
                <div className="input-container">
                  <input
                    placeholder="MM"
                    value={month}
                    maxLength={2}
                    onChange={(e) => setMonth(e.target.value)}
                    style={{ maxWidth: '100%', color: (isUnderAge || numericError) ? block.text_color : '#000', borderBottom: `2px solid ${block.border_color}` }}
                  />
                  <label>Month</label>
                </div>
                <div className="input-container">
                  <input
                      placeholder="DD"
                      value={day}
                      maxLength={2}
                      onChange={(e) => setDay(e.target.value)}
                      style={{ maxWidth: '100%', color: (isUnderAge || numericError) ? block.text_color : '#000', borderBottom: `2px solid ${block.border_color}` }}
                  />
                    <label>Day</label>
                </div>
                <div className="input-container">
                  <input
                      placeholder="YYYY"
                      value={year}
                      maxLength={4}
                      onChange={(e) => setYear(e.target.value)}
                      style={{ maxWidth: '100%', color: (isUnderAge || numericError) ? block.text_color : '#000', borderBottom: `2px solid ${block.border_color}` }}
                    />
                    <label>Year</label>
                </div>
              </div>
            </div>
            <button type="submit" style={{backgroundColor: block.button_color}}>{block.button_text}</button>
            {dateError && <p className="error-message">{dateError}</p>}
            {numericError && <p className="error-message">{numericError}</p>}
            <div>
              {!dateError && !numericError && getErrorMessage()}              
            </div>
            <div class="terms-and-privacy-container">
            {terms_link && privacy_link ? (
                <div class="mutli-link">
                  <a className="tp-link" href={terms_link}>TERMS AND CONDITIONS</a>
                  <a className="tp-link" href={privacy_link}>PRIVATE POLICY</a>
                </div>
              ) : terms_link ? (
                <a className="single-link" href={terms_link}>TERMS AND CONDITIONS</a>
              ) : privacy_link ? (
                <a className="single-link" href={privacy_link}>PRIVATE POLICY</a>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default DateAgeGate;
