import React from 'react';
import './instagram.scss';
import { trackInfluencerClick } from '../utils/gaTrackingFunctions';

function InstagramSection({ component }) {
  if (!component || !component.instagrampost_set) {
    return null; // Return something or render a placeholder if the block is not found
  }

  return (
    <div className="instagram-section">
      {component.instagrampost_set.map((post, index) => (
        <InstagramPost key={index} post={post} index={index} />
      ))}
    </div>
  );
}

function InstagramPost({ post, index }) {
  const { image, alt_text, text, url } = post;

  function mediaLink(type, url, img_path, title) {
    switch (type) {
      case 'pinterest':
        return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${img_path}&description=${encodeURIComponent(
          title
        )}`;
      case 'facebook':
        return `https://www.facebook.com/share.php?u=${url}&title=${encodeURIComponent(
          title
        )}`;
      case 'twitter':
        return `http://twitter.com/share?text=${encodeURIComponent(
          title
        )}&url=${url}`;
      default:
        return '';
    }
  }

  function handleClick() {
    trackInfluencerClick(url, index + 1, text, 'instagram');
  }

  return (
    <div className="instagram-post">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <img src={image} alt={alt_text} className="instagram-img" />
      </a>
      <div className='footer'>
        <div className="instagram-text">{text}</div>
        <div className="social-icons">
          {['pinterest', 'facebook', 'twitter'].map((media, i) => (
            <a
              href={mediaLink(media, url, image, text)}
              target="_blank"
              className="btn-social"
              key={i}
              rel="noopener noreferrer"
              onClick={() => trackInfluencerClick(mediaLink(media, url, image, text), index + 1, text, media)}
            >
              <i className={`fa fa-${media}`} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default InstagramSection;
