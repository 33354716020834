export const isLoggedIn = () => {
    return !!localStorage.getItem("token");
  };
  
  export const shouldRenderComponent = (authVisibility) => {
    const loggedIn = isLoggedIn();
  
    if (authVisibility === 'authenticated' && !loggedIn) {
      return false;
    }
  
    if (authVisibility === 'unauthenticated' && loggedIn) {
      return false;
    }
  
    return true;
  };
  